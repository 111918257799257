import { memo, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import clsx from "clsx";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import animationData from "./animationData.json";
import styles from "../BirdsClouds.module.scss";

const FetechingBestPrice = ({ className, onClick = null }) => {
    const lottieRef = useRef(null);

    useEffect(() => {
        lottieRef.current.play();
    }, []);

    const handelOnClickAway = () => {
        if (onClick) {
        onClick();
        }
    };

  return (
    <div className={clsx(className, styles.loading, styles.loadingSRP)}>
      <ClickAwayListener onClickAway={handelOnClickAway}>
        <div className={styles.innerwrap}>
          <Lottie
              loop={false}
              autoplay={false}
              lottieRef={lottieRef}
              animationData={animationData}
              className={styles.lottieContainer}
              style={{
                  position: 'absolute',
                  inset: 0
              }}
          />
          <div className={styles.content}>
            <h2>Please wait...</h2>
            <p>We’re checking for best prices and availability...</p>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(FetechingBestPrice);