import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import ListingDetails from "@/components/map/ListingDetails";
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import { CollapseCard } from "components/Molecules/Cards";
import Card from 'components/Atoms/Card';
import { MoreInfoIcon } from "components/Atoms/Icons";
import { DescriptionList } from "components/Molecules/Lists"
import { Tooltip } from "components/Molecules/Modal";
import {Modal} from "components/Molecules/Modal";
import { ListDetailModal } from "components/Molecules/Lists";
import { ExpandableList } from "components/Molecules/Lists";
import { Room } from "./Rooms";
import { Loader } from "components/Molecules/Loader";
import { FaceMaskOutlinedIcon } from "components/Atoms/Icons";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { config } from '@/utils/config';
import InfoIcon from "@/components/common/icons/Info2";
import { useMultiUnitAvailability } from "./AvailabilityOffer/useAvailability";
import SlidablePromoFiller from "./SlidablePromoFiller";
export const getName = item => item.name;
export const removeUnderscores = (str) => str.replace(/_/g, ' ');
export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
export const roomFilter = (string) => capitalizeFirstLetter(removeUnderscores(string));
export const filterBath = item => !item.toLowerCase().includes('bath');
export const mapPolicies = item => ({ title: item.title, description: item.description});


export const HotelLoading = ({productTitle}) => {
    const { isMobile, isTablet, isDesktop } = useDeviceDetect();
    const [isMessageShow, setIsMessageShow] = useState(false);
    const productUnits = useSelector(state => state.product.units);

    const { isAvailabilityDataExists } = useMultiUnitAvailability();

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setIsMessageShow(true);
        },500);

        return () => {
            clearTimeout(timer);
        };
    },[])
    const backTosrp = useCallback(() => {
        window.close();
        location.href = config.current_url;
    }, []);

    if (isMessageShow && productUnits.is_ok) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 4,
                    '& > div': {
                        position: 'initial',
                        transform: 'none'
                    }
                }}
            >
                <SlidablePromoFiller
                    isMobile={isMobile}
                />
            </Box>
        );
    }

    return (
        <>
            <Card
                className="card--white card--room-option"
                radius={isTablet || isDesktop}
            >
                <Card.Content>
                    <Room loading bigImage >
                        {
                            (isTablet || isDesktop)
                            && <ExpandableList
                                title={<Loader.Icon height={15} width={140} />}
                                limit={1}
                                items={[
                                    <Loader.Icon height={20} width={120} />
                                ]}
                            />
                        }
                    </Room>
                    <section className="details">
                        <ListDetailModal
                            enabled
                            title={<Loader.Icon height={15} width={120} />}
                            twoColumn={isTablet || isDesktop}
                            limit={isMobile ? 3 : 8}
                            btnFormat="Show all {num} amenities"
                            icon={null}
                            items={
                                isMobile
                                ? [
                                    <Loader.Icon height={20} width={120} />,
                                    <Loader.Icon height={20} width={120} />,
                                    <Loader.Icon height={20} width={120} />,
                                ]:[
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />,
                                <Loader.Icon height={20} width={120} />
                            ]}
                        />
                    </section>
                    {/* {isMessageShow && Boolean(productUnits.is_ok)  && <div className="hotel-message-loading">
                        <SlidablePromoFiller
                            isMobile={isMobile}
                        /> */}
                    {/* <div className="hotel-message-innerwrap">
                        {!isMobile && <div className="hotel-message-bg">
                            <div className="hotel-message-clouds cloud-1">
                                <img src="/static/img/hotelmessage/cloud_1.svg" />
                            </div>
                            <div className="hotel-message-clouds cloud-2">
                                <img src="/static/img/hotelmessage/cloud_2.svg" />
                            </div>
                            <div className="hotel-message-clouds cloud-3">
                                <img src="/static/img/hotelmessage/cloud_3.svg" />
                            </div>
                        </div>}
                        <div className="hotel-message-content">
                            <div className="hotel-message-logoicon">
                                <img src="/static/img/hotelmessage/logoicon.svg" />
                            </div>
                            <h2>Don’t Go Anywhere</h2>
                            <p>Interest in {productTitle} is much higher than usual. We just need a moment to confirm availability!</p>
                        </div>


                        </div> */}
                    {/* </div>} */}
                    {!productUnits.is_ok && !isAvailabilityDataExists() &&
                    <div className="hotel-message-loading">
                        <div className="hotel-message-innerwrap hotel-message-innerwrap_noproperty">
                            <span className="img-ohno"><img src="/static/img/hotelmessage/Expired-small.svg" /> </span>
                            <p className="message-ohno">Just missed it!</p>
                            <p>{productTitle}  has just sold out. </p>  <span>Keep browsing — we’re confident we’ll find your preferred stay! </span>
                            <div className="back-to-listing" onClick={backTosrp}>Back to listings</div>
                        </div>
                    </div>
                    }
                </Card.Content>
                <Card.Footer>
                    <Loader.Icon height={20} width={200} /><br />
                    <Loader.Icon height={34} width={170} /><br />
                    <Loader.Icon height={20} width={150} />
                </Card.Footer>
            </Card>
            <Loader.Icon height={34} width={200} />

        </>
    )
}

export const AccommodationPolicies = React.forwardRef(
    (
        { policies },
        ref
    ) => (
        !!policies.length
            && (
            <>
                <hr ref={ref} className='listings-section-separator' />
                <Card
                    title="Accommodation policies"
                    noPadding
                    className='product-section'
                >
                    <DescriptionList
                        items={policies}
                        itemMapper={(item, indx) => (
                            <React.Fragment key={indx}>
                                <h5>{item.title}</h5>
                                <div>
                                    {
                                        Array.isArray(item.description)
                                        && (typeof item.description[0] === 'object')
                                        && (
                                            item.description.map((description, _indx) => {
                                                if(description.category === '') {
                                                    return (
                                                        <p key={_indx} dangerouslySetInnerHTML={{ __html: (description.text.join('<br />') || '') }}></p>
                                                    )
                                                } else {
                                                    if(description.items)
                                                    return (
                                                        <React.Fragment key={_indx}>
                                                            <strong>{description.category}</strong>
                                                            <p dangerouslySetInnerHTML={{ __html: ((description.items || []).join('<br />') || '') }}></p>
                                                        </React.Fragment>
                                                    )
                                                }
                                            })
                                        )
                                    }
                                    {
                                        Array.isArray(item.description)
                                        && (typeof item.description[0] === 'string')
                                        && (
                                            <p dangerouslySetInnerHTML={{ __html: ((item.description || []).join('<br />') || '') }}></p>
                                        )
                                    }
                                </div>
                            </React.Fragment>
                        )}
                    />
                </Card>
            </>
        )
    )
);

export const AccommodationLocation = ({
    type = 'vr',
    address = '',
    lat,
    lng,
    neighbor_overview,
    neighbor_around
}) => (
    <Card
        title="Location"
        className="card--location"
        noPadding
        enabled={!!(address || (lat && lng))}
    >
        <p>{address}</p>
        <br />
        <div className="card--location__map">
            <ListingDetails
                type={type}
                lat={parseFloat(lat)}
                lng={parseFloat(lng)}
                shapeOptions={{
                    strokeColor: "transparent",
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillColor: "rgba(243, 85, 151, 0.2)",
                    fillOpacity: 1
                }}
                mapOptions={{
                    zoom: 15,
                    maxZoom: undefined
                }}
                radius={250}
            />
            {
                type === 'vr'
                && (
                    <em>We will provide you exact location information after your booking is confirmed.</em>
                )
            }
            {
                (!!neighbor_overview || !!neighbor_around)
                && (
                    <CollapseCard
                        noPadding
                        title="Neighborhood"
                        className="card--neighborhood"
                        content={[neighbor_overview, neighbor_around].filter(Boolean).join( '<br /><br />')}
                    />
                )
            }
        </div>
    </Card>
);

export const PriceBreakdownTooltip = ({
    item
}) => {
    if(!item?.prices.length) return null;
    const { isMobile, isTablet } = useDeviceDetect();

    return (
        <Tooltip
            enabled
            dark
            modalMode={isMobile || isTablet}
            modal={{
                title: 'Price breakdown',
                fullScreen: true
            }}
            content={
                <div className="listings-hotel-price-breakdown">
                    {
                        !isMobile
                        && (
                            <h5>Price breakdown</h5>
                        )
                    }
                    <ul>
                        {item.prices.map((item, indx)=>(
                            <li key={indx}>
                                <span>{item.name}</span>
                                <span>{item.amount}</span>
                            </li>
                        ))}
                        {
                            isMobile
                            && (
                                <>
                                    <li className="divider"></li>
                                    <li>
                                        <span><strong>Total</strong></span>
                                        <span><strong>{item.total.amount}</strong></span>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </div>
            }
        >
            <InfoIcon />
        </Tooltip>
    )
}

export const CancellationPolicy = ({ item, position = 'top' }) => {
    const { isDesktop } = useDeviceDetect();
    if (!item || !Object.keys(item).length ) return null;
    let content = '';
    let name = item.type;
    let href = '';

    //TODO : BE should fix this issue and make a constant
    // object, till then, let handle it by our side
    if(Array.isArray(item.policies))
        content = item.policies.map(item => item.text).join('<br />');
    else if(typeof item.policies === 'object') {
        content = item.policies.text;
        if(item.policies.name)
            name = item.policies.name;
        if(item.policies.href)
            href = item.policies.href;
    } else if(Array.isArray(item.text)) {
        content = item.text.join('<br />');
    }

    const name_ary = name.split(' ');
    let left_name = '';
    let right_name = '';
    if(name_ary.length >= 3){
        left_name = name_ary.slice(0, name_ary.length -1).join(' ');
        right_name = name_ary[name_ary.length-1]
    } else {
        left_name = name;
    }

    const tooltip_content = (
        <>
            &nbsp;
            <Tooltip
                enabled
                dark
                content={content}
                modalMode={!isDesktop}
                position={position}
                modal={{
                    title: item.type,
                    fullScreen: true
                }}
            >
                &nbsp;
                <InfoIcon />
            </Tooltip>
        </>
    )
    return (
        <span className="cancellation-policy-span">
            {
                href ? <span>
                            <a href={href} target="_blank">{left_name} {right_name}</a>{tooltip_content}

                       </span>
                        :
                       <>
                             {left_name} {right_name} {tooltip_content}
                       </>

            }
        </span>
    );
}

export const SafetyMessage = ({ className }) => {

    const [showBanner,setShowBanner] = useState(true);

    if(!showBanner){
        return null;
    }

    //--Methods
    const handleCloseClick = () => setShowBanner(false);

    return showBanner && (
        <div className={clsx('safety-message', className)}>
            <FaceMaskOutlinedIcon />
            <div>
                <strong>COVID-19 sanitation measures are in place at this property</strong>
                <p>As a result, certain services and amenities may be reduced</p>
            </div>
            <svg width="24" height="24" onClick={handleCloseClick} style={{cursor: 'pointer'}}>
                <path d="M8 8L16.4853 16.4853" stroke="#797882" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.4854 8L8.00007 16.4853" stroke="#797882" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
};
